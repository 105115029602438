<template>
  <div v-cloak>

    <!--------------------------------->
    <!--Шаг1--------------------------->
    <!--------------------------------->
    <!--------------------------------->
    <!--------------------------------->

    <transition name="fade" appear appear-active-class="fade-enter-active" v-if="step===1 && apiLoaded">
      <div class="wrap">
        <div class="goBack" @click="$router.go(-1)">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
          </svg>
          назад
        </div>

        <h1>
          <span>Беседа с заявителем.<br>Выявление желаемой профессии</span>
        </h1>

        <p>{{ application.lastName }} {{ application.firstName }} {{ application.middleName }}</p>

        <div class="form-wrap">
          <v-select dense outlined :items="professions" item-value="id" item-text="name"
                    v-model="assessment.desiredProfession"
                    return-object="return-object"></v-select>
        </div>


        <table class="table">
          <thead>
          <tr>
            <th>Уровень образования</th>
            <th>Квалификация</th>
            <th>Специальность по диплому</th>
            <th>Компеценции</th>
            <th>Уровень развития</th>
          </tr>
          </thead>
          <tbody v-if="assessment.desiredProfession != null">
          <tr v-for="(reqSkill, reqSkillIndex) in assessment.desiredProfession.requiredSkills"
              :key="'skillst1td1'+reqSkillIndex">
            <td v-if="reqSkillIndex===0" :rowspan="assessment.desiredProfession.requiredSkills.length">
              {{ EducationLevels[assessment.desiredProfession.reqEducation.educationLevel] }}
            </td>
            <td v-if="reqSkillIndex===0" :rowspan="assessment.desiredProfession.requiredSkills.length">
              {{ assessment.desiredProfession.reqEducation.qualification }}
            </td>
            <td v-if="reqSkillIndex===0" :rowspan="assessment.desiredProfession.requiredSkills.length">
              {{ assessment.desiredProfession.reqEducation.diplomaSpeciality }}
            </td>
            <td>{{ reqSkill.skill.name }}</td>
            <td>{{ reqSkill.mark }}</td>
          </tr>
          </tbody>
        </table>
        <v-btn :disabled="assessment.desiredProfession==null" color="blue" style="width: 360px" @click="step++">ДАЛЕЕ
        </v-btn>
      </div>
    </transition>


    <!--------------------------------->
    <!--Шаг2--------------------------->
    <!--------------------------------->
    <!--------------------------------->
    <!--------------------------------->

    <transition name="fade" appear appear-active-class="fade-enter-active" v-if="step===2 && apiLoaded">
      <div class="wrap">
        <div class="goBack" @click="step--">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
          </svg>
          назад
        </div>

        <h1>
          <span>Беседа с заявителем.<br>Выявление желаемой профессии</span>
        </h1>

        <p>{{ application.lastName }} {{ application.firstName }} {{ application.middleName }}</p>

        <div class="form-wrap">
          <v-select readonly dense outlined :items="professions" item-value="id" item-text="name"
                    v-model="assessment.desiredProfession"
                    return-object="return-object"></v-select>
        </div>


        <p>Выберете выявленные гражданином компетенции</p>

        <div class="skills-list">
          <div class="left">
            <h2>Справочник компетенций</h2>
            <div>
              <v-checkbox
                  v-for="(skill, skillIndex) in skills"
                  :key="'vcheckboxskill'+skillIndex"
                  :label="skill.name"
                  :input-value="findSkillInAssessmentEntries(skill) != null"
                  @change="toggleSkill(skill, $event)"
              ></v-checkbox>
            </div>
          </div>
          <div class="right">
            <h2>Компетенции гражданина</h2>
            <div>
              <v-chip
                  class="ma-2"
                  color="#F3F6FD"
                  v-for="(entry, entryIndex) in assessment.assessmentEntries"
                  :key="'vchipprof'+entryIndex"
              >
                {{ entry.skill.name }}
              </v-chip>
            </div>
          </div>
        </div>

        <v-btn color="blue" style="width: 360px" @click="assessment.assessmentEntries.length > 0 ? step++ : step+=2">
          ДАЛЕЕ
        </v-btn>
      </div>
    </transition>


    <!--------------------------------->
    <!--Шаг3--------------------------->
    <!--------------------------------->
    <!--------------------------------->
    <!--------------------------------->

    <transition name="fade" appear appear-active-class="fade-enter-active" v-if="step===3 && apiLoaded">
      <div class="wrap">
        <div class="goBack" @click="step--">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z" fill="#0033A0"/>
          </svg>
          назад
        </div>

        <h1>
          <span>Задача: оценка компетенций заявителя</span>
        </h1>

        <p>{{ application.lastName }} {{ application.firstName }} {{ application.middleName }}</p>

        <p>Укажите уровень компетенций гражданина (фактический)</p>


        <table class="table">
          <thead>
          <tr>
            <th>Компетенции гражданина</th>
            <th>Выберите уровень развития</th>
            <th>Укажите источник</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(entry, entryIndex) in assessment.assessmentEntries"
              :key="'step3tr_entry'+entryIndex">
            <td>{{ entry.skill.name }}</td>
            <td>
              <v-select dense style="width: 120px"
                        v-model="entry.mark"
                        :items="availableMarks" item-value="value" item-text="label"></v-select>
            </td>
            <td>
              <v-select dense style="width: 170px"
                        :items="AssessmentMethods"
                        item-value="value" item-text="title"
                        v-model="entry.assessmentMethod"
              ></v-select>
            </td>
          </tr>
          </tbody>
        </table>
        <v-btn :disabled="!allSkillsAssessed()" color="blue" style="width: 360px" @click="step++">ДАЛЕЕ</v-btn>
      </div>
    </transition>


    <!--------------------------------->
    <!--Шаг4--------------------------->
    <!--------------------------------->
    <!--------------------------------->
    <!--------------------------------->
    <transition name="fade" appear appear-active-class="fade-enter-active" v-if="step===4 && apiLoaded">
      <assessment-component
          :application="application" :assessment="assessment"
          @goBack="assessment.assessmentEntries.length > 0 ? step-- : step-=2"></assessment-component>
    </transition>

  </div>
</template>

<script>

import api from "@/modules/api";
import {AssessmentMethods, EducationLevels, mapToObject} from "@/modules/NSI";
import AssessmentComponent from "@/components/AssessmentComponent";

export default {
  name: 'Directories',
  components: {AssessmentComponent},
  data() {
    return {
      step: 1,
      EducationLevels: EducationLevels,
      AssessmentMethods: mapToObject(AssessmentMethods),
      professions: [],
      skills: [],
      application: null,
      assessment: {
        applicationId: null,
        userId: null,
        desiredProfession: null,
        assessmentEntries: [],
        educationLevelCheck: false,
        qualificationCheck: false,
        diplomaSpecialityCheck: false,
        localDateTime: null
      },
      availableMarks: [{label: '1', value: 1}, {label: '2', value: 2}, {label: '3', value: 3},
        {label: '4', value: 4}, {label: '5', value: 5}],
      apiLoaded: false,
      apiError: false
    };
  },
  methods: {
    async getAllProfessions() {
      let req = await api.get("/professions?size=10000")
      if (req.ok) {
        this.professions = req.payload._embedded.professions
        console.log(this.professions)
      } else alert('Ошибка при обращении к серверу!')
    },
    async getAllSkills() {
      let req = await api.get("/skills?size=10000")
      if (req.ok) {
        this.skills = req.payload._embedded.skills
        console.log(this.skills)
      } else alert('Ошибка при обращении к серверу!')
    },
    async getApplication(appId) {
      let req = await api.get("/applications/" + appId)
      if (req.ok) {
        this.application = req.payload
        console.log(this.application)
      } else alert('Ошибка при обращении к серверу!')
    },

    toggleSkill(skill, e) {
      if (e) {
        this.addSkillToAssessmentEntries(skill)
      } else
        this.removeSkillFromAssessmentEntries(skill)
    },

    addSkillToAssessmentEntries(skill) {
      this.assessment.assessmentEntries.push({skill: skill, mark: null, assessmentMethod: 'SELF'})
    },

    removeSkillFromAssessmentEntries(skill) {
      for (let i = 0; i < this.assessment.assessmentEntries.length; i++) {
        if (this.assessment.assessmentEntries[i].skill.id === skill.id) {
          this.assessment.assessmentEntries.splice(i, 1)
          break
        }
      }
    },

    allSkillsAssessed() {
      for (let e of this.assessment.assessmentEntries) {
        if (e.mark == null || e.assessmentMethod == null)
          return false
      }
      return true
    },

    findSkillInAssessmentEntries(skill) {
      for (let e of this.assessment.assessmentEntries) {
        if (e.skill.id === skill.id || e.skill.name === skill.name)
          return e
      }
    },
  },
  async beforeMount() {
    this.assessment.applicationId = this.$route.params.id
    this.assessment.userName = this.$user.userName
    this.assessment.userFullName = this.$user.lastName + ' ' + this.$user.firstName + ' ' +this.$user.middleName
        let p1 = this.getAllProfessions()
    let p2 = this.getAllSkills()
    let p3 = this.getApplication(this.assessment.applicationId)
    await p1
    await p2
    await p3

    this.assessment.applicantFullName = this.application.lastName + ' ' + this.application.firstName + ' ' + this.application.middleName
    this.apiLoaded = true
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>


<style scoped lang="scss">
</style>
